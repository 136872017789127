import classNames from 'classnames';
import styles from './Form.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { Grid } from 'ui/components/4-habitats/Grid/Grid';
import { GridCell } from 'ui/components/4-habitats/Grid/GridCell';
import ColorOptions, { GridLayoutTypeOptions } from 'helpers/types';
import { OrttoForm } from 'ui/components/2-molecules/OrttoForm/OrttoForm';
import { Kicker, KickerProps } from 'ui/components/1-atoms/Kicker/Kicker';

export interface FormProps {
	bgColor?: ColorOptions;
	kicker: KickerProps;
	heading: string;
	image: {
		url: string;
		altText?: string;
	};
	formHtmlCode: string;
	showImage?: boolean;
	layoutType?: GridLayoutTypeOptions;
	displayButtonRight?: boolean;
	selectAllLabel?: string;
	inputFullWidth?: boolean;
}

export const Form: React.FC<FormProps> = ({
	bgColor = 'white',
	kicker,
	heading,
	image,
	formHtmlCode,
	showImage = false,
	layoutType,
	displayButtonRight,
	selectAllLabel,
	inputFullWidth,
}) => {
	return (
		<div className={classNames(styles.Form, styles[`u-color___${bgColor}`])}>
			<Container width="Standard">
				<TopLevelGrid container wrap>
					<TopLevelGridCell desktopWidth="16col" mobileWidth="100">
						{kicker && <Kicker className={styles.Form_trumpetHeading} {...kicker}></Kicker>}

						{heading && (
							<Heading className={styles.Form_heading} headingLevel="h2" style="xl">
								{heading}
							</Heading>
						)}
					</TopLevelGridCell>

					{showImage && (
						<TopLevelGridCell desktopWidth="22col" mobileWidth="100" desktopOffset="0col">
							<Grid container wrap largeGutter>
								{layoutType === 'oneThirdImage' ? (
									<>
										<GridCell desktopWidth="33" mobileWidth="100">
											{image && (
												<Picture
													className={styles.Form_image}
													url={image.url}
													altText={image.altText}
													sizes="(max-width: 768px) 100vw, (max-width: 1728px) 66vw, 1150px"
												/>
											)}
										</GridCell>
										<GridCell desktopWidth="66" mobileWidth="100" className={classNames('ortto')}>
											<OrttoForm
												className="two-thirds"
												formHtmlCode={formHtmlCode}
												displayButtonRight={displayButtonRight}
												selectAllLabel={selectAllLabel}
												inputFullWidth={inputFullWidth}
											/>
										</GridCell>
									</>
								) : layoutType === 'halfImage' ? (
									<>
										<GridCell desktopWidth="50" mobileWidth="100">
											{image && (
												<Picture
													className={styles.Form_image}
													url={image.url}
													altText={image.altText}
													sizes="(max-width: 768px) 100vw, (max-width: 1728px) 66vw, 1150px"
												/>
											)}
										</GridCell>
										<GridCell desktopWidth="50" mobileWidth="100">
											<OrttoForm
												className="half"
												formHtmlCode={formHtmlCode}
												displayButtonRight={displayButtonRight}
												selectAllLabel={selectAllLabel}
												inputFullWidth={inputFullWidth}
											/>
										</GridCell>
									</>
								) : (
									<>
										<GridCell desktopWidth="66" mobileWidth="100">
											{image && (
												<Picture
													className={styles.Form_image}
													url={image.url}
													altText={image.altText}
													sizes="(max-width: 768px) 100vw, (max-width: 1728px) 66vw, 1150px"
												/>
											)}
										</GridCell>
										<GridCell desktopWidth="33" mobileWidth="100">
											<OrttoForm
												formHtmlCode={formHtmlCode}
												displayButtonRight={displayButtonRight}
												selectAllLabel={selectAllLabel}
												inputFullWidth={inputFullWidth}
											/>
										</GridCell>
									</>
								)}
							</Grid>
						</TopLevelGridCell>
					)}

					{!showImage && (
						<TopLevelGridCell desktopWidth="24col" mobileWidth="100" desktopOffset="0col">
							<Grid container wrap>
								<GridCell desktopWidth="100" mobileWidth="100">
									<OrttoForm
										className="ortto-noImage"
										formHtmlCode={formHtmlCode}
										displayButtonRight={displayButtonRight}
										selectAllLabel={selectAllLabel}
										inputFullWidth={inputFullWidth}
									/>
								</GridCell>
							</Grid>
						</TopLevelGridCell>
					)}
				</TopLevelGrid>
			</Container>
		</div>
	);
};
